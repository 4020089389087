:root {
  --qichm-verev: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  overflow-x: hidden;
}

html,
body,
#root {
  width: 100vw;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CheddarGothic-sans";
  src: url("./assets/fonts/cheddargothic-sans-webfont.woff2") format("woff2"),
    url("./assets/fonts/cheddargothic-sans-webfont.woff") format("woff"),
    url("./assets/fonts/cheddargothic-sans-webfont.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

h1 {
  font-family: "CheddarGothic-sans";
  font-size: 3.2vw;
  line-height: 3.2vw;
}

h2 {
  font-family: "CheddarGothic-sans";
  font-size: 3.3vw;
  line-height: 3.3vw;
}

h3 {
  font-family: "CheddarGothic-sans";
  font-size: 2.5vw;
  line-height: 2.5vw;
}

h4 {
  font-family: "Poppins";
  line-height: 27px;
  color: #7c7c7c;
  text-transform: uppercase;
  margin-block-end: 0;
}

h5 {
  font-family: "Poppins";
  font-size: 1vw;
  line-height: 1vw;
}

.text-green {
  color: #55b103;
}

header {
  position: relative;
  overflow: hidden;
}

header > video {
  width: 100%;
}

header > heading {
  margin-block-start: 130px;
  margin-block-end: 75px;
}

header > h3 {
  margin-block-end: 45px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  padding: 30px 3% 50px 3%;
}

.header-meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header-meta > img {
  width: 12vw;
}

.socials {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

.connect-wallet-button {
  width: 100%;
}

.socials > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vw;
  width: 3vw;

  border: 1px solid #7c7c7c;
  border-radius: 4px;
}

.socials > a > img {
  height: 24px;
}

.connect-button {
  font-family: "Poppins";
  font-size: 1vw;
  font-weight: 600;

  padding: 1vw 1.5vw;

  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: rgba(85, 177, 3, .8);
  text-transform: uppercase;
  color: white;
}


.scroll-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: inherit;
  font-size: 6rem;
  cursor: pointer;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.info {
  text-align: center;
  padding: 30px 10% 50px 10%;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.sections > section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sections > section:first-of-type {
  align-items: flex-start;
}

.sections > section > * {
  max-width: 33vw;
}

section > img {
  padding: 0 4vw;
}

.sections > section > span,
p {
  font-size: 1.125rem;
  line-height: 1.688rem;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.links > img {
  width: 7vw;
}

.site-link {
  font-family: "Poppins";
  border: 1px solid white;
  border-radius: 4px;
  font-size: 1vw;
  text-transform: uppercase;
  text-decoration: none;
  padding: 11px 3.5vw;
  color: white;

  transition: color cubic-bezier(0.04, 0.43, 0.59, 0.51) 250ms;
  transition: background-color cubic-bezier(0.04, 0.43, 0.59, 0.51) 250ms;
}

.site-link:hover {
  color: black;
  background-color: white;
}

.copyright {
  text-transform: none;
  font-weight: 400;
}

.form-counter-container {
  margin-top: 12px;
}

.form-counter{
  display: flex;
  align-items: center;
  gap: 24px;
}

.counter-btn {
  font-family: "Poppins";
  font-size: 1vw;
  font-weight: 600;
  padding: .1vw 1.5vw;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  color: white;
  transition: background-color cubic-bezier(0.04, 0.43, 0.59, 0.51) 250ms;
}

.form-counter > span {
  font-size: 2.5vw;
}

.info .connect-container {
  display: none;
}

header .connect-container {
  margin-block-start: 12px;
}

header .form-counter-container > h5 {
  float: right;
  margin-block-start: var(--qichm-verev);
}

@media screen and (max-width: 1220px) {
  header > video {
    object-position: -24vw;
    width: 145%;
  }

  .header-meta > img {
    width: 20vw;
  }

  header h1,
  h3 {
    display: none;
  }

  h2 {
    font-size: 8vw;
    line-height: 8vw;
  }

  .socials {
    display: none;
  }

  .scroll-arrow {
    display: none;
  }

  .sections > section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .sections > section > * {
    max-width: 100%;
  }

  .sections > section:nth-of-type(1),
  section:nth-of-type(3) {
    flex-direction: column-reverse;
  }

  .sections > section:nth-of-type(3) > div {
    margin-block-end: 32px;
  }

  .form-counter {
    justify-content: center;
  }
  
  .form-counter,
  .connect-button {
    font-size: 4.5vw;
  }
  .form-counter .connect-button,
  .counter-btn{
    font-size: 4.5vw;
    margin-block-start: 0;
  }
  .form-counter > span{
    font-size: 4.5vw;
  }

  .site-link {
    font-size: 3vw;
  }

  .links > img {
    width: 20vw;
  }

  .info .connect-container {
    display: initial;
  }

  header .connect-container {
    display: none;
  }

  h5 {
    font-size: 4vw;
  }
}
